const getVendorTierPrices = (state) => {
  return state.vendorTierPrices;
}

const getVendorCustomers = (state) => {
  return state.vendorCustomers;
}

export default {
  getVendorTierPrices,
  getVendorCustomers
}