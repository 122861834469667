import { isEmpty } from 'lodash';
import { notification } from 'ant-design-vue';
import axios from '@/lib/axios';

export default {
  state: {
    details: {
      labFee: 0,
      ispackage: false,
      productBundle: [],
      marketplaceStoreFront: true,
      practiceStoreFront: false,
      boundByState: false,
      description: '',
      detail: '',
      id: '',
      images: [],
      files: [],
      manufacturer: '',
      name: '',
      categorySlug: '',
      subcategorySlug: '',
      productPricing: {},
      selectedCategory: 0,
      registrationRequired: false,
      sku: '',
      vendorSku: '',
      mfgSku: '',
      vin: '',
      vendorId: '',
      slug: '',
      weight: '',
      weightTypeId: 1,
      cardVolume: '',
      inStock: false,
      limited: false,
      limitedQty: 500,
      minQtyForPurchase: 1,
      rateType: '',
      labtestId: '',
    },
    pricing: [],
    pricingInCurrentTier: [],
    labs: [],
    allStoreFrontProducts: [],
    onlineStoreProducts: [],
    collectionKit: {},
    // ADD-EDIT PRODUCT
    productDetailsForm: {
      labFee: 0,
      ispackage: false,
      productBundle: [],
      marketplaceStoreFront: true,
      practiceStoreFront: false,
      boundByState: false,
      description: '',
      detail: '',
      id: '',
      images: [],
      files: [],
      manufacturer: '',
      name: '',
      categorySlug: '',
      subcategorySlug: '',
      productPricing: {},
      selectedCategory: 0,
      registrationRequired: false,
      sku: '',
      vendorSku: '',
      vin: '',
      vendorId: '',
      slug: '',
      weight: 1,
      weightTypeId: 1,
      cardVolume: '',
      inStock: false,
      limited: false,
      limitedQty: 500,
      minQtyForPurchase: 1,
      rateType: 'C',
      labtestId: '',
      isVisibleMedcenter: false,
      productPricingTiers: [],
      isVirtualProduct: false,
    },
  },

  getters: {
    getProductDetailsForm: (state) => {
      return state.productDetailsForm;
    },
    getCollectionKit: (state) => {
      return state.collectionKit;
    },
    getAllStoreFrontProducts: (state) => {
      return state.allStoreFrontProducts;
    },
    getOnlineStoreProducts: (state) => {
      return state.onlineStoreProducts;
    },
    getAllLaboratory: (state) => {
      return state.labs;
    },
    getProductsBundle: (state) => {
      return state.details.productBundle;
    },
    getPricingInTier: (state) => {
      return state.pricingInCurrentTier.map((singlePackage) => {
        return {
          packageSize: singlePackage.packageSize,
          price: singlePackage.finalPrice,
          marketFee: singlePackage.marketFee,
        };
      });
    },
    getCurrentVendorProductPricing: (state) => {
      return state.pricing;
    },
    getCurrentVendorProductCategories: (state) => {
      return {
        value: state.productDetailsForm.categorySlug,
        children: state.productDetailsForm.subcategorySlug,
      };
    },
    getCurrentVendorProduct: (state) => {
      return state.details;
    },
    getCurrentVendorProductValidated: (state) => {
      return !isEmpty(state.productDetailsForm.vendorSku)
        && !isEmpty(state.productDetailsForm.name)
        && !isEmpty(state.productDetailsForm.categorySlug)
        && !isEmpty(state.productDetailsForm.subcategorySlug)
        && !isEmpty(state.productDetailsForm.description)
        && state.productDetailsForm.weight > 0
        && state.productDetailsForm.minQtyForPurchase > 0
        && !isEmpty(state.productDetailsForm.mfgSku);
    },
    getProductPricingTiers: (state) => {
      return state.productPricingTiers;
    },
  },

  mutations: {
    setCollectionKit(state, payload) {
      state.collectionKit = payload;
    },
    setCurrentVendorProduct(state, payload) {
      state.details = payload;
    },
    setProductPricingInTier(state, payload) {
      state.pricingInCurrentTier = payload;
    },
    setCurrentVendorProductCategoryId(state, payload) {
      state.productDetailsForm.categorySlug = payload[0];
      state.productDetailsForm.subcategorySlug = payload[1];
    },
    setProductsBundle(state, payload) {
      state.productDetailsForm.productBundle = payload;
    },
    setAllLabs(state, payload) {
      state.labs = payload;
    },
    setOnlineStoreProducts(state, payload) {
      state.onlineStoreProducts = payload;
    },
    setAllStoreFrontProducts(state, payload) {
      state.allStoreFrontProducts = payload;
    },
    setProductFormDetails(state, payload) {
      state.productDetailsForm = payload;
    },
    // VENDOR PRODUCT PAGE PRICES
    setProductPricingTiers(state, payload) {
      state.productPricingTiers = payload;
    },
    setProductFormProperty(state, payload) {
      state.productDetailsForm[payload.fieldName] = payload.value;
    },
  },

  actions: {
    fetchCollectionKitPrice: async ({ commit }, payload) => {
      const { orgId } = payload;
      const response = await axios.get(
        `/products/v1/org/${orgId}/collectionKitPrice`,
      );
      commit('setCollectionKit', response.data);
    },
    addCollectionKitPrice: async ({ commit }, payload) => {
      const response = await axios.post(
        '/products/v1/org/collectionKitPrice', payload,
      );
      commit('setCollectionKit', response.data);
    },
    commitOnlineStoreProductsDetails: ({ commit }, payload) => {
      commit('setOnlineStoreProductsDetails', payload);
    },
    fetchAllStoreFrontProducts: async ({ commit }) => {
      const response = await axios.get(
        '/products/v1/vendor/landproducts',
      );
      commit('setAllStoreFrontProducts', response.data);
    },
    fetchOnlineStoreProducts: async ({ commit }, payload) => {
      const { orgId } = payload;
      const response = await axios.get(
        `/accounts/v1/open/landing/org/${orgId}/products`,
      );
      commit('setOnlineStoreProducts', response.data);
    },
    commitProducts: ({ commit }, payload) => {
      commit('setProductsBundle', payload);
    },
    fetchAllLabs: async ({ commit }) => {
      const response = await axios.get(
        '/accounts/v1/vendor/getlabs',
      );
      commit('setAllLabs', response.data);
    },
    fetchProductPricingByVendorTier: async ({ commit }, payload) => {
      try {
        const { vendorId } = payload;
        const { productId } = payload;
        const { tierId } = payload;
        const response = await axios.get(
          `/products/v1/vendor/${vendorId}/product/${productId}/tier/${tierId}`,
        );

        // commit('setProductPricingInTier', response.data);
        return response.data;
      } catch (error) {
        notification.warning({
          description: 'Failed to fetch product pricing tier',
        });
        return null;
      }
    },
    updateProductPricingInTier: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { productId } = payload;
      const body = payload.data;

      const response = await axios.put(
        `/products/v1/vendor/${vendorId}/product/${productId}/tier`,
        body,
      );
      commit('setProductPricingInTier', response.data);
    },
    updateCurrentVendorProduct: async ({ commit, getters }) => {
      const currentProduct = getters.getProductDetailsForm;
      const isBundleFlag = getters.getProductDetailsForm.ispackage;
      // if (!getters.getCurrentVendorProductValidated) {
      //   return false;
      // }

      const { vendorId } = currentProduct;
      const productId = currentProduct.id;
      const list = getters.getProductsBundle;

      const body = {
        productName: currentProduct.name,
        description: currentProduct.description,
        sku: currentProduct.sku,
        vendorSku: currentProduct.vendorSku,
        mfgSku: currentProduct.mfgSku,
        registrationRequired: currentProduct.registrationRequired,
        boundByState: currentProduct.boundByState,
        categorySlug: currentProduct.categorySlug,
        subcategorySlug: currentProduct.subcategorySlug,
        weight: currentProduct.weight,
        weightTypeId: currentProduct.weightTypeId,
        cardVolume: currentProduct.cardVolume,
        limited: currentProduct.limited,
        limitedQty: currentProduct.limitedQty,
        minQtyForPurchase: currentProduct.minQtyForPurchase,
        marketplaceStoreFront: currentProduct.marketplaceStoreFront,
        practiceStoreFront: currentProduct.practiceStoreFront,
        childProducts: currentProduct.childProducts,
        isBundle: isBundleFlag,
        rateType: currentProduct.rateType,
        labtestId: currentProduct.labtestId,
        labFee: currentProduct.labFee,
        isVisibleMedcenter: currentProduct.isVisibleMedcenter,
        isVirtualProduct: currentProduct.isVirtualProduct,
      };

      try {
        const response = await axios.put(
          `/products/v1/vendor/${vendorId}/product/${productId}`,
          body,
        );
        commit('setCurrentVendorProduct', response.data);

        if (getters.getProductDetailsForm.productBundle.length > 0) {
          const value = [];
          getters.getProductDetailsForm.productBundle.forEach((product) => {
            value.push(product.childProduct);
          });
          commit('setProductsBundle', value);
        }

        notification.success({
          description: 'Successfully updated product',
        });

        return response.data;
      } catch (error) {
        notification.warning({
          description: 'Failed to update product',
        });
        commit('setProductsBundle', null);
        return null;
      }
    },

    addNewProduct: async ({ commit, dispatch, getters }, payload) => {
      const currentProduct = getters.getProductDetailsForm;
      let vendorId = '';
      if (getters.getVendorId !== undefined) {
        vendorId = getters.getVendorId;
      } else {
        vendorId = payload.vendorId;
      }

      // if (!getters.getCurrentVendorProductValidated) {
      //   return false;
      // }

      const body = {
        productName: currentProduct.name,
        description: currentProduct.description,
        sku: currentProduct.sku,
        vendorSku: currentProduct.vendorSku,
        mfgSku: currentProduct.mfgSku,
        registrationRequired: currentProduct.registrationRequired,
        boundByState: currentProduct.boundByState,
        categorySlug: currentProduct.categorySlug,
        subcategorySlug: currentProduct.subcategorySlug,
        weight: currentProduct.weight,
        weightTypeId: currentProduct.weightTypeId,
        cardVolume: currentProduct.cardVolume,
        limited: currentProduct.limited,
        limitedQty: currentProduct.limitedQty,
        minQtyForPurchase: currentProduct.minQtyForPurchase,
        marketplaceStoreFront: currentProduct.marketplaceStoreFront,
        practiceStoreFront: currentProduct.practiceStoreFront,
        childProducts: [],
        isBundle: false,
        rateType: currentProduct.rateType,
        labtestId: currentProduct.labtestId,
        labFee: currentProduct.labFee,
        isVisibleMedcenter: currentProduct.isVisibleMedcenter,
        isVirtualProduct: currentProduct.isVirtualProduct,
      };

      try {
        const response = await axios.post(
          `/products/v1/vendor/${vendorId}/product`,
          body,
        );

        notification.success({
          description: 'Successfully added bundle',
        });

        commit('setProductFormDetails', response.data);
        commit('setCurrentVendorProduct', response.data);
        dispatch('fetchAllVendorProducts', vendorId);
        return response.data;
      } catch (error) {
        notification.warning({
          description: 'Failed to add product',
        });
        return null;
      }
    },

    addImagesToProduct: async ({ commit, getters }, payload) => {
      const body = payload.images;
      const { productId } = payload;
      const { vendorId } = payload;

      const response = await axios.put(
        `/products/v1/vendor/${vendorId}/product/${productId}/image`,
        body,
      );
      commit('setCurrentVendorProduct', response.data);
      if (getters.getCurrentVendorProduct.productBundle.length > 0) {
        const value = [];
        getters.getCurrentVendorProduct.productBundle.forEach((product) => {
          value.push(product.childProduct);
        });
        commit('setProductsBundle', value);
      }
    },

    removeImageFromProduct: async ({ commit }, payload) => {
      const { productId } = payload;
      const { vendorId } = payload;
      const { imageId } = payload;

      const response = await axios.delete(
        `/products/v1/vendor/${vendorId}/product/${productId}/image/${imageId}`,
      );
      commit('setCurrentVendorProduct', response.data);
      return response;
    },

    removeFileFromProduct: async ({ commit, getters }, payload) => {
      const productId = getters.getCurrentVendorProduct.id;
      const { vendorId } = getters.getCurrentVendorProduct;
      const { fileId } = payload;

      const response = await axios.delete(
        `/products/v1/vendor/${vendorId}/product/${productId}/file/${fileId}`,
      );
      commit('setCurrentVendorProduct', response.data);
      return response;
    },

    commitCurrentVendorProduct: ({ commit }, payload) => {
      commit('setCurrentVendorProduct', payload);
    },
    commitCurrentVendorProductCategories: ({ commit }, payload) => {
      commit('setCurrentVendorProductCategoryId', payload);
    },
    resetCurrentVendorProduct: ({ commit }) => {
      const details = {
        boundByState: false,
        description: '',
        detail: '',
        id: '',
        images: [],
        manufacturer: '',
        name: '',
        categorySlug: '',
        subcategorySlug: '',
        productPricing: {},
        registrationRequired: false,
        sku: '',
        vendorSku: '',
        mfgSku: '',
        slug: '',
        vendor: {},
        weight: 0,
        weightTypeId: 1,
        cardVolume: '',
        marketplaceStoreFront: true,
        practiceStoreFront: false,
        rateType: 'C',
        isVisibleMedcenter: false,
        minQtyForPurchase: 0,
        isVirtualProduct: false,
      };
      commit('setProductFormDetails', details);
    },
  },
};
