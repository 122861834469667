import shippingCarrierConstant from "@/constants/shipping-carrier-constant";

const setVendorInfo = (state, payload) => {
  if (payload.type === "update" && payload.propName) {
    state.vendorInfo[payload.propName] = payload.value;
  } else {
    if(payload.shippingCarrier) {
      const shippingCarrierId = shippingCarrierConstant[payload.shippingCarrier].id;
      payload.shippingCarrierId = shippingCarrierId;
    }
    state.vendorInfo = payload;
  }
};

export default {
  setVendorInfo
};

