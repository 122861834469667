import axios from '@/lib/axios';
import { notification } from 'ant-design-vue';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    deleteProductPricingInTier: async (_, payload) => {
      const { vendorId } = payload;
      const { productId } = payload;
      const { tierId } = payload;

      try {
        const response = await axios.delete(
          `/products/v1/vendor/${vendorId}/product/${productId}/tier/${tierId}`,
        );

        return response;
      } catch (error) {
        notification.warning({
          description: 'Failed to delete pricing tier',
        });
        return null;
      }
    },
  },
};
