const setVendorTierPricing = (state, payload) => {
  state.vendorTierPrices = payload;
}

const setVendorCustomers = (state, payload) => {
  state.vendorCustomers = payload;
}

const updateTierMemberList = (state, payload) => {
  const selectedIndex = state.vendorTierPrices.findIndex((tier) => tier.id === payload.tierId);
  const members = payload.orgs.map((org) => {
    const pricingTier = {
      id: state.vendorTierPrices[selectedIndex].id,
      name: state.vendorTierPrices[selectedIndex].name
    };

    // UPDATING THE CUSTOMER TIER
    const orgIdIndex = state.vendorCustomers.findIndex((customer) => customer.id === org.organizationId);
    state.vendorCustomers[orgIdIndex].tier = pricingTier;
    // END

    return {
      orgId: org.organizationId,
      orgName: org.organizationName,
      pricingTier
    }
  });

  // REMOVING THE CUSTOMER TIER
  if (payload.deleteFromTiers) {
    payload.deleteFromTiers.map((memberOrgId) => {
      const orgIdIndex = state.vendorCustomers.findIndex((customer) => customer.id === memberOrgId);
      state.vendorCustomers[orgIdIndex].tier = null;
    })
  }
  state.vendorTierPrices[selectedIndex].memberHistory = members;
}


const updateVendorPricingTiers = (state, payload) => {
  if (payload.isAdd) {
    state.vendorTierPrices.push(payload.data);
  } else if (payload.isDelete) {
    const selectedIndex = state.vendorTierPrices.findIndex((item) => { return item.id === payload.id; });
    state.vendorTierPrices.splice(selectedIndex, 1);
  }
}

export default {
  setVendorTierPricing,
  setVendorCustomers,
  updateTierMemberList,
  updateVendorPricingTiers
}