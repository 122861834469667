const getProductDetailsForm = (state) => {
  return state.productDetailsForm;
}

const getVendorPricingTiers = (state) => {
  return state.pricingTiers.sort((a, b) => {
    if (a.base < b.base) {
      return 1;
    }
    if (a.base === b.base) {
      if (a.name > b.name) {
        return 1;
      }
      return -1;
    }
    return -1;
  });
}

export default {
  getProductDetailsForm,
  getVendorPricingTiers
}