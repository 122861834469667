import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchVendorTierPricing = async ({ commit }, vendorId) => {
  try {
    const response = await axios.get(`/products/v1/vendor/${vendorId}/tier1`);
    if (response) {
      const { data } = response;
      commit("setVendorTierPricing", data);
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const fetchVendorCustomers = async ({ commit }, vendorId) => {
  try {
    const response = await axios.get(`/orders/v1/vendor/${vendorId}/sellers`);
    if (response) {
      const { data } = response;
      commit("setVendorCustomers", data);
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const updateOrgsInPricingTier = async (_, payload) => {
  const body = payload.orgs; // orgs is an array of objects with id and name keys
  const { tierId } = payload;
  const { vendorId } = payload;

  try {
    const response = await axios.put(`products/v1/vendor/${vendorId}/tier/${tierId}/member`, body);

    notification("success", "Successfully updated pricing tier");
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const updateTierFee = async (_, payload) => {
  const body = {
    marketFee: payload.marketFee,
  };
  const { tierId } = payload;
  const { vendorId } = payload;

  try {
    await axios.put(`products/v1/vendor/${vendorId}/tier/${tierId}/marketfee`, body);
    notification("success", "Tier fee updated successfully!");
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const deletePricingTier = async ({ commit }, payload) => {
  const { vendorId } = payload;
  const { tierId } = payload;

  try {
    await axios.delete(`/products/v1/vendor/${vendorId}/tier/${tierId}`);
    commit("updateVendorPricingTiers", { isDelete: true, id: tierId });

    notification("success", "Successfully deleted tier");
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const addPricingTier = async ({ commit }, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const { vendorId } = payload;
  const body = {
    vendorId: payload.vendorId,
    tierName: payload.tierName,
    isHidden: payload.isHidden,
  };

  const query = `products/v1/vendor/${vendorId}/tier`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added tier!",
  };
  const response = await proxy.execute(query, body, notificationParams);
  if (response) {
    const { data } = response;
    commit("updateVendorPricingTiers", { isAdd: true, data });
  }
};

export default {
  fetchVendorTierPricing,
  fetchVendorCustomers,
  updateOrgsInPricingTier,
  updateTierFee,
  deletePricingTier,
  addPricingTier,
};
