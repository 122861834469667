import { get } from 'lodash';

const setInventorySlots = (state, payload) => {
  state.inventorySlots = payload;
}

const updateActivationCodeStatus = (state, payload) => {
  const isUnited = get(payload, "isUnited", false);
  const used = get(payload, "used", false);

  // eslint-disable-next-line arrow-body-style
  const slotIndex = state.inventorySlots.findIndex((slot) => slot.slotNumber === payload.slotNumber);
  // eslint-disable-next-line arrow-body-style
  const activationCodeIndex = state.inventorySlots[slotIndex].activationCodes.findIndex((activation) => activation.activationCode === payload.barCode);
  state.inventorySlots[slotIndex].activationCodes[activationCodeIndex].isUnited = isUnited;
  state.inventorySlots[slotIndex].activationCodes[activationCodeIndex].used = used;
}

const updateInventorySlotsList = (state, payload) => {
  state.inventorySlots.unshift(payload[0]);
}

const setVendorInvoice = (state, payload) => {
  state.vendorInvoice = payload;
}

export default {
  setInventorySlots,
  updateActivationCodeStatus,
  updateInventorySlotsList,
  setVendorInvoice,
}